













import Vue from "vue";
import DataContainer from "@vuetifyx/common/DataContainer";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import appService from "@/services/appService";
import Loading from "@/components/Loading.vue";
import BlurredTenant from "@/components/BlurredTenant.vue";

export default Vue.extend({
  components: { Loading, BlurredTenant },
  data() {
    return {
      showBlur: userManager.checkRole(["cpoAdmin", "cpoMonitor"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
      self: this,
      tenantId: "",
      loading: false,
      dataContainer: new DataContainer(),
      formOptions: {
        attrs: {
          title: "Exchange ratio",
        },
        content: {
          ignoreOtherFields: true,
          sections: {
            default: {
              fields: {
                exchangeRatioFromCurrency: {
                  type: "select",
                  attrs: {
                    label: "Currency",
                    required: true,
                    readonly: true,
                    items: [
                      {
                        text: "VND",
                        value: "vnd",
                      },
                      {
                        text: "USD",
                        value: "usd",
                      },
                    ],
                  },
                  ext: {
                    defaultValue: "vnd",
                  },
                },
                chargingSessionMinPoint: {
                  type: "number",
                  attrs: {
                    label: "Minimum amount to start charging",
                    required: true,
                  },
                },
                chargingSessionPointPerKwh: {
                  type: "number",
                  attrs: {
                    label: "Price per kWh including tax",
                    required: true,
                  },
                },
                tax: {
                  type: "number",
                  attrs: {
                    label: "Tax %",
                    required: true,
                  },
                },
              },
            },
          },
          actionButtons: userManager.checkRole(["cpoAdmin"]) && {
            save: {
              content: {
                text: "Save",
              },
              on: {
                async xClick({ self }) {
                  const form = self.context();
                  const { tenantId } = form.context();
                  const data = form.getData();
                  form.loading.value = true;
                  self.loading.value = true;
                  await coreApiClient.call(
                    "tenants",
                    "updateSetting",
                    {
                      id: tenantId,
                    },
                    data
                  );
                  self.loading.value = false;
                  form.loading.value = false;
                  form.success.value = "Exchange ratio saved successfully";
                },
              },
            },
          },
        },
      },
      formContainer: new DataContainer(),
    };
  },
  async created() {
    if (userManager.checkRole(["systemAdmin", "admin"])) {
      const activeTenant = appService.getActiveTenant();
      if (activeTenant) {
        this.tenantId = activeTenant._id;
      }
    } else if (userManager.checkRole(["cpoAdmin", "cpoMonitor"])) {
      this.tenantId = userManager.getUserInfo().tenant._id;
    }
    if (this.tenantId) {
      this.loading = true;
      const data = await coreApiClient.call("tenants", "getSetting", {
        id: this.tenantId,
      });
      this.dataContainer.value = data;
      this.loading = false;
    }
  },
});
